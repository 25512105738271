import React, {useState} from "react"
import parse from 'html-react-parser';
import Image from '../ResuableImageWrapper';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_testimonials_wrap.scss";

const BasicTestimonial = (data, { isInView }) => {
  
  let classList = [
    'testimonials-wrap', 
    'section',
    // 'is_inview', 
    'active'
  ]; 

 
  if(data.removeBottomMargin === true){
    classList.push('no-margin');
  }
  
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section  className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`} data-bg-shape="show_shape">
          <div className="wrapper">
            <div className="testimonials-wrap-inner">
              <div className="testimonial">{parse(data.quotation)}</div>
              <label className="name">{data.personPositionCompany}</label>
              <div className="background-image">
                <div className="inner">
                  <div className="colour-overlay"></div>
                  <Image 
                    image={data?.personPhoto?.localFile?.childImageSharp?.fluid} 
                    alt={data?.personPhoto?.altText}
                    offsets={data.personPhotoOffsets}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </VisibilitySensor>
  )
}

export default BasicTestimonial

