import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import Image from "../ResuableImageWrapper";
import styled from "styled-components";
import VisibilitySensor from "react-visibility-sensor";
import "../../../sass/components/reuseable_components/_text-image.scss";
import { Link } from "gatsby";

const ImageWrapperOuter = styled.div`
  background: linear-gradient(
    ${(props) => props.customColour1},
    ${(props) => props.customColour2}
  ) !important;
`;
const ContainsCustomColours = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    background: var(--black);
    background: -webkit-linear-gradient(
      right,
      ${(props) => props.customColour1},
      ${(props) => props.customColour2}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const ServiceTextImageBlock = (data) => {
  let removeBottomMargin = data.removeBottomMargin;
  let backgroundColour1 = data.backgroundColour1;
  let backgroundColour2 = data.backgroundColour2;
  let useCustomBackgroundColourOnImage = data.useCustomBackgroundColour;
  let image = data?.image?.localFile?.childImageSharp?.fluid;
  let alt = data?.image?.altText;
  let imageFocalPoint = data.imageFocalPoint;
  let imageToFillSpace = data.imageToFillSpace;
  let label = data.label;
  let padding = data.padding;
  let button = data.textimagebutton;
  let textimagebuttontext = button.textimagebuttontext;
  let textimagebuttonlink = button.textimagebuttonlink;
  let text = parse(data.text);

  //console.log(data?.image)

  let classList = ["text-image", "contains-custom-colour", "section"];

  if (removeBottomMargin === true) {
    classList.push("no-margin");
  }
  if (imageFocalPoint === true) {
    classList.push(imageFocalPoint);
  }

  const [isInview, setIsInview] = useState(false);
  const [customColour1, setCustomColour1] = useState();
  const [customColour2, setCustomColour2] = useState();

  useEffect(() => {
    if (typeof document !== "undefined") {
      setCustomColour1(
        document.querySelector(".outer-component-wrapper").getAttribute("data-color1")
      );
      setCustomColour2(
        document.querySelector(".outer-component-wrapper").getAttribute("data-color2")
      );
    }
  }, []);

  function inviewOnChange(isVisible) {
    setIsInview(isVisible);
  }

  console.log("data", data);
  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ bottom: 120 }}
      active={!isInview}
      onChange={inviewOnChange}>
      {({ isVisible }) => (
        <section
          className={`${classList.join(" ")} ${isInview ? "is_inview" : ""}`}
          data-bg-shape="show_shape">
          <ContainsCustomColours
            customColour1={customColour1}
            customColour2={customColour2}
            className="wrapper">
            <div className="text-area no-margin">
              {text}
              {button && textimagebuttontext && textimagebuttonlink && (
                <Link to={textimagebuttonlink} className="button btn btn--grad link">
                  <span>{textimagebuttontext}</span>
                </Link>
              )}
            </div>

            <ImageWrapperOuter
              customColour1={
                useCustomBackgroundColourOnImage === true ? backgroundColour1 : customColour1
              }
              customColour2={
                useCustomBackgroundColourOnImage === true ? backgroundColour2 : customColour2
              }
              className={imageToFillSpace === true ? "image cover" : "image contain"}>
              {useCustomBackgroundColourOnImage}
              <Image image={image} label={label} padding={padding} alt={alt} />
            </ImageWrapperOuter>
          </ContainsCustomColours>
        </section>
      )}
    </VisibilitySensor>
  );
};

export default ServiceTextImageBlock;
