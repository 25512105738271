import React, {useState, useEffect, useRef} from "react"
import parse from 'html-react-parser';
import styled from 'styled-components';
import {Link} from 'gatsby';
import Image from '../ResuableImageWrapper';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_case_studies_related_posts.scss";

const ContainsCustomColours = styled.div`

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    width:auto !important;
    background:-webkit-linear-gradient(right, var(--col1), var(--col2));
    background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .button-custom-colour{
    background:linear-gradient(to bottom, ${(props) => props.customColour1}, ${(props) => props.customColour2}) !important;
  }
  .ul-custom-colour {
    ul li:before{
      background:linear-gradient(to right, ${(props) => props.customColour1}, ${(props) => props.customColour2}) !important;
    }
  }
`

const BasicRelatedPosts = (data) => {
  const removeBottomMargin = data.removeBottomMargin
  const text = data.text;
  const relatedPosts = data.postPicker;

  let classList = [
    // 'text-area', 
    'contains-custom-colour',
    'section',
    'case-studies-related-posts'
  ]; 

  if(removeBottomMargin === true){
    classList.push('no-margin');
  }
  
  
  const [isInview, setIsInview] = useState(false);
  const [customColour1, setCustomColour1] = useState();
  const [customColour2, setCustomColour2] = useState();
  const [currentService, setCurrentService] = useState();

  const wrapper = useRef()
  
  useEffect(() => {
    if(typeof document !== 'undefined'){
        setCustomColour1(document.querySelector('.outer-component-wrapper').getAttribute('data-color1'))
        setCustomColour2(document.querySelector('.outer-component-wrapper').getAttribute('data-color2'))
        setCurrentService(document.querySelector('.outer-component-wrapper').getAttribute('data-case-title'))
    }
  }, [])

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }


  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section ref={wrapper} className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`}>
          <ContainsCustomColours customColour1={customColour1} customColour2={customColour2} className="wrapper">
            {
              text ? 
                <div className="introText">
                  {parse(text)}
                </div>
              : ""
            }
          </ContainsCustomColours>
          <div className="case-studies-wrapper">
            <div className="wrapper-large">
              <div className={`article-wrap articles-count-${relatedPosts.length}`}>
                {relatedPosts.map((post, index) => {
                  return(
                    <article className="" key={`basic_related_posts_${index}`}>
                      <Link to={post.pickPost.link}>
                          <Image image={post.pickPost?.acf_caseStudyFields?.backgroundImage?.localFile?.childImageSharp?.fluid || post.pickPost?.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid} alt={post.pickPost?.acf_caseStudyFields?.backgroundImage?.altText} />
                          <div className="info">
                            <div className="label">{post.label}</div>
                            <h3>{post.headline}</h3>
                            <div className="rollover">
                            <ContainsCustomColours customColour1={customColour1} customColour2={customColour2}>
                              <div className="ul-custom-colour">{parse(post.rolloverText)}</div>
                              <span className="button button-custom-colour">{post.buttonText ? post.buttonText : `${currentService ? currentService+' Case Study' : 'Read more'}`}</span>
                            </ContainsCustomColours>
                            </div>
                          </div>
                        </Link>
                      </article>
                  )
                })}
              </div>
            </div>				
          </div>
        </section>
      }
    </VisibilitySensor>
  )
}

export default BasicRelatedPosts

