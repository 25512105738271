
import React, {useState, useEffect} from 'react'
import LottieWrapVideo from '../../lottie/lottieWrapVideo';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'gatsby';

export default function ServiceGridItem({video, title, text, linkToService}) {

  const [isInview, setIsInview] = useState(false);
  const [offset, setOffset] = useState();

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  useEffect(() => {
   if(typeof window !== 'undefined'){
        const ww = window.innerWidth

        //console.log(ww)

        if(ww > 1024){
            setOffset(220)
        }else{
            setOffset(0)
        }
   }
  })

  

    return (
        <VisibilitySensor partialVisibility={true} offset={{bottom:offset}} active={!isInview}  onChange={inviewOnChange}>
            {({isVisible}) =>
                <article className={`${isInview ? 'is_inview' : ''}`}>
                    <div className="lottie">
                    <LottieWrapVideo checkIsInview={true} isInview={isInview} video={video} />
                    </div>                      
                    {linkToService ? <Link to={linkToService.uri}><h3>{title}</h3></Link>: <h3>{title}</h3> }
                    <div className="text">{text}</div>
                    {linkToService ? <Link to={linkToService.uri} className="button btn btn--grad link"><span>Find out more</span></Link> : ''}
                </article>
            }
        </VisibilitySensor>
    
    )
}
