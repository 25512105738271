import React, {useState, useEffect} from "react"
import parse from 'html-react-parser';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_text-areas.scss";

const ContainsCustomColours = styled.div`

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    background:var(--grey--dark);
    background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: auto;
  }

  ul{
    li:before{
      background-color: ${(props) => props.customColour2} !important; 
    }

    &.styled_bullet_list{
      color: ${(props) => props.customColour2} !important; 
    }
  }
`

const Line = styled.div`
 background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2}) !important; 
`


const BasicTextBlock = ({ addDividerLine, removeBottomMargin, text}) => {

  let classList = [
    'text-area', 
    'contains-custom-colour',
    'section'
  ]; 
    
  if(removeBottomMargin === true){
    classList.push('no-margin');
  }
  
  const [isInview, setIsInview] = useState(false);
  const [customColour1, setCustomColour1] = useState();
  const [customColour2, setCustomColour2] = useState();
  
  useEffect(() => {
    if(typeof document !== 'undefined'){
        setCustomColour1(document.querySelector('.outer-component-wrapper').getAttribute('data-color1'))
        setCustomColour2(document.querySelector('.outer-component-wrapper').getAttribute('data-color2'))
    }
  }, [])

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  return ( 
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`} data-bg-shape="show_shape">
          <ContainsCustomColours customColour1={customColour1} customColour2={customColour2} className="wrapper-900">
              { addDividerLine ? <Line  customColour1={customColour1} customColour2={customColour2} className="line"></Line> : "" }
              {parse(text)}
          </ContainsCustomColours>
        </section>
      }
    </VisibilitySensor>

  )
}

export default BasicTextBlock

