import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import styled from "styled-components";
import VisibilitySensor from "react-visibility-sensor";
import "../../../sass/components/reuseable_components/_accordion.scss";
import AccordionComponent from "./AccordionComponent";
import { Helmet } from "react-helmet";

const ContainsCustomColours = styled.div`
  h1.heading-tag,
  h2.heading-tag,
  h3.heading-tag,
  h4.heading-tag,
  h5.heading-tag,
  h6.heading-tag {
    background: var(--grey--dark);
    background: -webkit-linear-gradient(
      right,
      ${(props) => props.customColour1},
      ${(props) => props.customColour2}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ul {
    li:before {
      background-color: ${(props) => props.customColour2} !important;
    }

    &.styled_bullet_list {
      color: ${(props) => props.customColour2} !important;
    }
  }

  .accordion--item--button::after {
    background: linear-gradient(
      to right,
      ${(props) => props.customColour1},
      ${(props) => props.customColour2},
      #ffffff,
      #ffffff
    );
  }

  .accordion--item--button svg {
    color: ${(props) => props.customColour1};
  }

  .acc-heading {
    margin-bottom: 0 !important;
    font-family: var(--font--reg);
    font-size: 2.4rem;
    font-weight: 700;
  }
`;

const Line = styled.div`
  background: -webkit-linear-gradient(
    right,
    ${(props) => props.customColour1},
    ${(props) => props.customColour2}
  ) !important;
`;

const BasicAccordion = ({ accordion, sectionTitle, removeBottomMargin }) => {
  let classList = ["text-area", "accordion-section", "contains-custom-colour", "section"];

  if (removeBottomMargin === true) {
    classList.push("no-margin");
  }

  // console.log(accordion);
  // console.log(sectionTitle);

  const [isInview, setIsInview] = useState(false);
  const [customColour1, setCustomColour1] = useState();
  const [customColour2, setCustomColour2] = useState();

  useEffect(() => {
    if (typeof document !== "undefined") {
      setCustomColour1(
        document.querySelector(".outer-component-wrapper").getAttribute("data-color1")
      );
      setCustomColour2(
        document.querySelector(".outer-component-wrapper").getAttribute("data-color2")
      );
    }
  }, []);

  function inviewOnChange(isVisible) {
    setIsInview(isVisible);
  }

  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ bottom: 120 }}
      active={!isInview}
      onChange={inviewOnChange}>
      {({ isVisible }) => (
        <section
          className={`${classList.join(" ")} ${isInview ? "is_inview" : ""}`}
          data-bg-shape="show_shape">
          <ContainsCustomColours
            customColour1={customColour1}
            customColour2={customColour2}
            className="wrapper-900">
            {/* {sectionTitle.headingType === "h1" ? (
              <h1 className="heading-tag">{sectionTitle.titleText}</h1>
            ) : (
              ""
            )}
            {sectionTitle.headingType === "h2" ? (
              <h2 className="heading-tag">{sectionTitle.titleText}</h2>
            ) : (
              ""
            )}
            {sectionTitle.headingType === "h3" ? (
              <h3 className="heading-tag">{sectionTitle.titleText}</h3>
            ) : (
              ""
            )}
            {sectionTitle.headingType === "h4" ? (
              <h4 className="heading-tag">{sectionTitle.titleText}</h4>
            ) : (
              ""
            )}
            {sectionTitle.headingType === "h5" ? (
              <h5 className="heading-tag">{sectionTitle.titleText}</h5>
            ) : (
              ""
            )}
            {sectionTitle.headingType === "h6" ? (
              <h6 className="heading-tag">{sectionTitle.titleText}</h6>
            ) : (
              ""
            )} */}

            <h2 className="heading-tag">{sectionTitle.titleText}</h2>

            <AccordionComponent accordion={accordion} />
            <Helmet>
              <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [
                      ${accordion.map((item, index) => {
                        return `{
                              "@type": "Question",
                              "name": "${item.heading
                                .replace(/(\r\n|\n|\r)/gm, "")
                                .replaceAll('"', "'")}",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "${item.text
                                  .replace(/(\r\n|\n|\r)/gm, "")
                                  .replaceAll('"', "'")}"
                              }
                            }`;
                      })}
                    ]}`}
              </script>
            </Helmet>
          </ContainsCustomColours>
        </section>
      )}
    </VisibilitySensor>
  );
};

export default BasicAccordion;
