import React, {useState} from "react"
import Image from '../ResuableImageWrapper';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_brands_wrap.scss";

const BasicBrandsLogos = (data, { removeBottomMargin, label, brandLogosGatsby, removeGrayscale }) => {
  

  const the_label = label || data.label;
  const logos = brandLogosGatsby || data.brandLogosGatsby
  const isGrayscale = brandLogosGatsby || data.removeGrayscale

  let classList = [
    'brands-wrap-outer', 
    'section'
  ]; 

  if(removeBottomMargin === true){
    classList.push('no-margin');
  }

  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }


  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`}>
          <div className={`${isGrayscale === true ? 'normal' : 'grayscale'} brands-wrap`}>
            <h2 className="label">{the_label}</h2>
            <ul>
              {
                logos.map((logo, index) => {
                  return(
                    <li key={`basic_brand_logo_${index}`}>
                      <Image image={logo?.logo?.localFile?.childImageSharp?.fluid} alt={logo?.logo?.altText}/>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </section>
      }
    </VisibilitySensor>
  )
}

export default BasicBrandsLogos

