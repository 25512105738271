import React, {useState, useEffect} from "react"
import VisibilitySensor from 'react-visibility-sensor';import parse from 'html-react-parser';
import styled from 'styled-components';
import ServiceGridItem from './ServiceGridItem'
import "../../../sass/components/reuseable_components/_services_grid.scss";

const ImageWrapperOuter = styled.div`
  background:linear-gradient(to bottom, ${(props) => props.backgroundColour1}, ${(props) => props.backgroundColour2}) !important;
`
const ContainsCustomColours = styled.div`

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    background:var(--black);
    background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  a h3{
  border-bottom:2px solid ${(props) => props.customColour2+'00'};
  transition: all 0.5s ease-in-out;
  position: relative;
  display: inline-block;

    &:hover{
      border-bottom:2px solid ${(props) => props.customColour2+'99'};
    }

  }

  .btn{
    position: relative;
    margin-top: 2rem;
    display: inline-block;
    font-family: var(--font--bold);
    color: ${(props) => props.customColour2};
    border:1px solid ${(props) => props.customColour2};
    font-size:1.6rem;
    width: 100%;

      &:before{
      background: ${(props) => props.customColour2};
      }

      span{
        width: 100%;
        display: inline-block;
        text-align: center;
      }

      &:hover{
        color:#ffffff;

          &:before{
          opacity: 0.9;
          }

      }
  }
`

const ServicesGridVideoTitleText = (data) => {
  
  let removeBottomMargin = data.removeBottomMargin;
  let backgroundColour1 = data.backgroundColour1;
  let backgroundColour2 = data.backgroundColour2;
  let video = data.videoUrl;
  
  // console.log(data);
  function inviewOnChange (isVisible) {
    return isVisible;
  }
  
  let classList = [
    'services_grid', 
    'contains-custom-colour',
    'section'
  ]; 
  
  if(removeBottomMargin === true){
    classList.push('no-margin');
  }
 
  const [isInview, setIsInview] = useState(false);
  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  const [customColour1, setCustomColour1] = useState();
  const [customColour2, setCustomColour2] = useState();
  
  useEffect(() => {
    if(typeof document !== 'undefined'){
        setCustomColour1(document.querySelector('.outer-component-wrapper').getAttribute('data-color1'))
        setCustomColour2(document.querySelector('.outer-component-wrapper').getAttribute('data-color2'))
    }
  }, [])
  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
    {({isVisible}) =>
      <section className={classList.join(" ")} data-bg-shape="show_shape">
        <ContainsCustomColours customColour1={customColour1 !== null ? customColour1 : ''} customColour2={customColour2} className="wrapper">
          <h2 className="title">{data.servicesGridHeading}</h2>
          <ul className="the-grid">
            {data.servicesServicesGridVideoTitleText.map((item, index) => {
              return(
                <li>
                    <ServiceGridItem 
                    video={item.videoUrl}
                    title={item.title}
                    text={item.text}
                    linkToService={item.linkToService}
                    />
                </li>
              )
            })}
          </ul>
        </ContainsCustomColours>
      </section>
    }
  </VisibilitySensor>
  )
}

export default ServicesGridVideoTitleText
