import React, {useState, useEffect} from "react"
import parse from 'html-react-parser';
import Image from '../ResuableImageWrapper';
import styled from 'styled-components';
import {Link} from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_cta-section.scss";

const ContainsCustomColours = styled.div`
background: -webkit-linear-gradient(right, ${(props) => props.backgroundColor1}, ${(props) => props.backgroundColor2}) !important;
`

const ServiceCallToActionWithImage  = (data) => {
  
  let removeBottomMargin = data.removeBottomMargin;
  let heading = data.heading;
  let text = data.text;
  let image = data.image?.localFile?.childImageSharp?.fluid;
  let alt = data.image?.altText
  let button = data.buttonGatsby;
  let backgroundColor1 = data.backgroundColor1;
  let backgroundColor2 = data.backgroundColor2;
  let headingTagOpen
  let headingTagClose

  
  let classList = [
    'cta-section',
    'section',
    'orange-grad',
    'cta-section-image'
  ]; 
  
  if(removeBottomMargin === true){
    classList.push('no-margin');
  }

  const getHeadingWrapper = (heading) => {
    const headingTagSetter = data.headingTag;
    let e = document.querySelector('span.headingWrapper');
    let d
    if(headingTagSetter === 'h1'){
      d = document.createElement('h1');
    }
    if(headingTagSetter === 'h2'){
      d = document.createElement('h2');
    }
    if(headingTagSetter === 'h3'){
      d = document.createElement('h3');
    }
    if(headingTagSetter === 'h4'){
      d = document.createElement('h4');
    }
    if(headingTagSetter === 'h5'){
      d = document.createElement('h5');
    }
    if(headingTagSetter === 'h6'){
      d = document.createElement('h6');
    }
    if(headingTagSetter === undefined || headingTagSetter === null){
      d = document.createElement('h2');
    }

    d.innerHTML = e.innerHTML;
    e.parentNode.replaceChild(d, e);
  }
  
  

  const [isInview, setIsInview] = useState(false);
  const [customColour1, setCustomColour1] = useState();
  const [customColour2, setCustomColour2] = useState();
  
  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }
  
  
  useEffect(() => {
    if(typeof document !== 'undefined'){
        getHeadingWrapper()
        setCustomColour1(document.querySelector('.outer-component-wrapper').getAttribute('data-color1'))
        setCustomColour2(document.querySelector('.outer-component-wrapper').getAttribute('data-color2'))
    }
  }, [])

  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
    {({isVisible}) =>
    
      <ContainsCustomColours className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`}  data-bg-shape="show_shape" backgroundColor1={backgroundColor1} backgroundColor2={backgroundColor2}>
        <div className="wrapper clearfix">
          <div className="image">
          <Image 
            image={image} 
            alt={alt}
          />
          </div>
          <article className="text">
            <span className="headingWrapper">{heading}</span>
            {parse(text)}
            <Link to={button.buttonLink.link} className="btn btn--white">{button.buttonText}</Link>
          </article>
        </div>
      </ContainsCustomColours>
    
    }
  </VisibilitySensor>
  )
}

export default ServiceCallToActionWithImage 
