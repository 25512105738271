import React from "react"
import parse from 'html-react-parser';
import Img from 'gatsby-image';
import styled from 'styled-components';

const ImageWrapper = styled.div`
/*  Need to sort padding and opacity */
max-width:${(props) => props.maxWidth} !important;
margin:0 auto;

  .contain{
    img{
    object-fit:contain !important;
    }
  }

  img{
  transform:translate(${props => props.offsets.offsetX}%,${props => props.offsets.offsetY}%);
  }

  &.topLeft img{
    object-position: 0% 0% !important;
  }
  &.topCentre img{
    object-position: 50% 0% !important;
  }
  &.topRight img{
    object-position: 100% 0% !important;
  }
  &.middleLeft img{
    object-position: 0% 50% !important;
  }
  &.middleCentre img{
    object-position: 50% 50% !important;
  }
  &.middleRight img{
    object-position: 100% 50% !important;
  }
  &.bottomLeft img{
    object-position: 0% 100% !important;
  }
  &.bottomCentre img{
    object-position: 50% 100% !important;
  }
  &.bottomRight img{
    object-position: 100% 100% !important;
  }

`

const Image = ({ image, label, maxWidth, alt, offsets, focalPoint, objectFit }) => {

// console.log(offsets)
  return (
      <ImageWrapper 
      className={`image-wrap ${focalPoint}`}
      maxWidth={maxWidth} 
      offsets={
        offsets ? {
          offsetX: offsets.offsetX !== null ? parseInt(offsets.offsetX) : "0", offsetY: offsets.offsetY !== null ? parseInt(offsets.offsetY) : "0"
        } 
        : {offsetX: 0, offsetY: 0}
        }
      >
        <div className="tint"></div>
        <Img fluid={image} className={objectFit} objectFit={'contain'} alt={alt ? alt : ""}/>
        {label ? <div className="label">{parse(label)}</div> : ""}
        
      </ImageWrapper>
  )
}

export default Image
