import React from "react";
import parse from "html-react-parser";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';
import { BsPlusCircle } from "react-icons/bs";

export default function AccordionComponent({ accordion }) {
  const handleClick = (e) => {
    const panel = e.target
      .closest(".accordion--item")
      .querySelector(".accordion--item--panel");
    const button = e.target
      .closest(".accordion--item")
      .querySelector(".accordion--item--button");

    if (panel.classList.contains("visible")) {
      panel.classList.remove("visible");
      button.classList.remove("visible");
    } else {
      panel.classList.add("visible");
      button.classList.add("visible");
    }
  };
  return (
    <div className="accordion-wrapper">
      <div className="accordion">
        {accordion.map((item) => {
          return (
            <div className="accordion--item" onClick={(e) => handleClick(e)}>
              <div className="accordion--item--heading">
                <div className="accordion--item--button">
                  <h3 className="acc-heading">{item.heading}</h3>
                  <BsPlusCircle />
                </div>
              </div>
              <div className="accordion--item--panel">
                <div className="panel-inner">{parse(item.text)}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
